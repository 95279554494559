import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TweenMax, Power1 } from 'gsap'

import { isTabletSelector } from '~state/app'
import LogoSvg from '~images/logo.svg'
import { LogoStyle } from './style'

const Logo = React.forwardRef((props, ref) => {
  const showHeader = useSelector(state => state.app.showHeader)
  const scrollDirection = useSelector(state => state.app.scrollDirection)
  const isTablet = useSelector(isTabletSelector)

  useEffect(() => {
    if (!showHeader && isTablet) {
      if (scrollDirection === 'up') {
        TweenMax.to(ref.current, 0.25, { opacity: 1, y: 0, ease: Power1.easeOut })
      } else if (scrollDirection === 'down') {
        TweenMax.to(ref.current, 0.25, { opacity: 0, y: -20, ease: Power1.easeOut })
      }
    }
  }, [scrollDirection, showHeader, isTablet])

  return (
    <LogoStyle className='logo' ref={ref}>
      <LogoSvg />
    </LogoStyle>
  )
})

export default Logo
