import React from 'react'
import PropTypes from 'prop-types'

import { TriangleStyle } from './style'
import TriangleLeftSvg from '~images/triangle-left.svg'
import TriangleBottomSvg from '~images/triangle-bottom.svg'

const Triangle = React.forwardRef(({ dir, className }, ref) => {
  return (
    <TriangleStyle className={className} ref={ref}>
      {['left', 'right'].indexOf(dir) >= 0 ? <TriangleLeftSvg /> : <TriangleBottomSvg />}
    </TriangleStyle>
  )
})

Triangle.propTypes = {
  dir: PropTypes.string,
  className: PropTypes.string
}

export default Triangle
