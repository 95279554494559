import styled from 'styled-components'
import { ruleRatioVw, media } from '~styles/utils'

export const LogoStyle = styled.div`
  position: fixed;
  ${ruleRatioVw('top', 80)};
  ${ruleRatioVw('left', 80)};
  /* ${ruleRatioVw('width', 135)}; */
  width: 135px;
  ${media.tabletPortrait`
    width: 80px;
  `}
  ${media.mobile`
    width: 80px;
  `}

  svg {
    max-width: 100%;
  }
}
`
