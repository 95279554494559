import { createGlobalStyle } from 'styled-components'
import styledSanitize from 'styled-sanitize'
import reset from 'styled-reset'
import { webfont, font, media } from './utils'
import theme from './theme'

export default createGlobalStyle`
  ${styledSanitize}
  ${reset}

  ${webfont('NeueHaasGrotesk', 'NHaasGroteskDSPro/55Rg', 500)}
  ${webfont('NeueHaasGrotesk', 'NHaasGroteskDSPro/45Lt', 400)}
  ${webfont('NeueHaasGrotesk', 'NHaasGroteskDSPro/35XLt', 300)}
  ${webfont('NeueHaasGrotesk', 'NHaasGroteskDSPro/25Th', 200)}
  
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    &._force-scroll {
      overflow-y: scroll;
    }
  }

  html {
    font-size: ${theme.fonts.sizes.base}px;
  }

  body {
    font-family: ${theme.fonts.families.neueHaasGrotesk};
    font-size: 100%;
    background: ${theme.colors.blue};
  }

   @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
     .image-container img { 
       min-height: 100%;
       min-width: 100%;
       width: auto !important;
       top: 50% !important;
       left: 50% !important;
       transform: translate(-50%, -50%);
     }
   }

  #___gatsby {
    min-height: 100%;
    font-size: ${theme.fonts.sizes.default};
    white-space: pre-line;
    color: ${theme.colors.white};
    overflow-x: hidden;
  }

  p {
    ${font(theme.fonts.formats.paragraph)}
    margin: 20px 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    outline: none;
  }

  img {
    width: 100%;
  }

  h1 {
    ${font(theme.fonts.formats.h1)}
    margin-bottom: 10px;
  }

  h2 {
    ${font(theme.fonts.formats.h2)}
    margin-bottom: 20px;
  }

  h3, .as-h3 {
    ${font(theme.fonts.formats.h3)}
    margin-bottom: 20px;
    ${media.mobile`
      margin-bottom: 0px;
    `}

  }

  .two-cols {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      width: 48%;
      max-width: 48%;
    }
    
    &:not(.-no-wrap-tablet)&:not(.-no-wrap) {
      > * {
        ${media.belowTablet`
          width: 100%;
          max-width: 100%;
        `}
      }
    }

    
    &:not(.-no-wrap) {
      > * {
        ${media.mobile`
          width: 100%;
          max-width: 100%;
        `}
      }
    }
  }

  .content-container {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .grow {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`
