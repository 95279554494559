import styled from 'styled-components'
import theme from '~styles/theme'
import { container, maxWidthContent, font, media } from '~styles/utils'

export const HeaderStyle = styled.header`
  height: 100vh;
  position: relative;
  opacity: 0; // for anim

  &.-thanks {
    background: ${theme.colors.darkBlue};
    overflow: hidden;
    position: fixed; // for anim
    width: 100%;
    opacity: 0; // for anim
    z-index: 10;
  }
  
  .triangle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  }

  .image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .text-container {
    ${container()}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    position: relative;
    z-index: 1;

    p {
      ${maxWidthContent()}
      ${font(theme.fonts.formats.headline)}
      ${media.tabletPortrait`
        max-width: 70%;
      `}
      ${media.mobile`
        max-width: 100%;
      `}
    }
  }
}
`
