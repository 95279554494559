/**
 * Styled Components Mixins
 *
 * TABLE OF CONTENT
 * --------
 *  =IMPORTS
 *  =LAYOUT
 *  =FONTS
 *  =MEDIA
 *
 */

/*
  ===IMPORTS===
*/
import styled, { css } from 'styled-components'
import { each } from 'lodash'
import theme from './theme'

/*
  ===LAYOUT===
*/

/**
 * Return vw value, based on px value regarding to basis (=PSD/sketch width)
 *
 * @param {Number} size
 * @param {Number} basis (default: theme.sizes.wBasis)
 */
export function ratioVw(size, basis = theme.sizes.wBasis) {
  return `${(size * 100) / basis}vw`
}

/**
 * Return CSS rules in vw for screen under theme.sizes.wBasis, in px for larger screen.
 * It allows to keep ratio in smaller screen (and prevent to much spaces for example).
 *
 * @param {String} rule
 * @param {Number} size
 * @param {Number} basis (default: theme.sizes.wBasis)
 */
export function ruleRatioVw(rule, size, basis = theme.sizes.wBasis) {
  return `
    ${rule}: ${size}px;
    @media screen and (max-width: ${basis}px) {
      ${rule}: ${ratioVw(size, basis)};
    }
    /*@media screen and (max-width: ${theme.media.mobile}) {
      ${rule}: ${size / 2}px;
    }*/
  `
}

/**
 * Return CSS rules for a max-width theme.sizes.wMax container, with safeMargin on smaller screen.
*/
export function container() {
  return `
    width: 100%;
    max-width: ${theme.sizes.wMax}px;
    margin: 0 auto;
    @media screen and (max-width: ${(theme.sizes.safeMargin * 2) + theme.sizes.wMax}px) {
      margin-left: ${theme.sizes.safeMargin}px;
      margin-right: ${theme.sizes.safeMargin}px;
      width: calc(100% - ${theme.sizes.safeMargin * 2}px);
    }
    @media screen and (max-width: ${theme.media.mobile}) {
      margin-left: ${theme.sizes.safeMarginMobile}px;
      margin-right: ${theme.sizes.safeMarginMobile}px;
      width: calc(100% - ${theme.sizes.safeMarginMobile * 2}px);
    }
    @media screen and (min-width: ${theme.media.veryLargeDesktop}) {
      max-width: ${theme.sizes.wMaxBig}px;
    }
  `
}

export const ContainerStyle = styled.div`
  ${container()}
`

/**
 * Return CSS rules for a max-width content on desktop.
*/
export function maxWidthContent() {
  return `
    @media screen and (min-width: ${theme.media.tablet}) {
      max-width: ${theme.sizes.maxContentWidth}px;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: ${theme.media.veryLargeDesktop}) {
      max-width: ${theme.sizes.maxContentWidthBig}px;
    }
  `
}

/*
  ===FONTS===
*/

/**
 * Register webfont
 * @param {String} family
 * @param {String} file
 * @param {Number} weight (default: 400)
 */
export function webfont(family, file, weight = 400) {
  const fileUrl = require(`~fonts/${file}.woff`); // eslint-disable-line
  return `
    @font-face {
      font-family: '${family}',
      src: url('${fileUrl}') format('woff'),
      font-weight: ${weight}
    }
  `
}

/**
 * Return rem value, based on pt value regarding to basis
 * @param {Number} pt
 * @param {Number} base (default: theme.fonts.sizes.base)
 */
export function ptToRem(pt, base = theme.fonts.sizes.base) {
  return `${pt / base}rem`
}

/**
 * Compute font-style
 * Example of options: {
 *  sizes: {
 *    desktop: '2.2rem',
 *    mobile: '1.6rem',
 *  },
 *  lineHeight: 1.2,
 *  ...
 * }
 * @param {Object} options
 */
export function font(options) {
  let fontCss = `
    font-size: ${options.sizes.desktop};
    @media (max-width: ${theme.media.tablet}) {
      font-size: ${options.sizes.tablet};
    }
    @media (max-width: ${theme.media.mobile}) {
      font-size: ${options.sizes.mobile};
    }
  `

  if (options.sizes.veryLargeDesktop) {
    fontCss += `
    @media (min-width: ${theme.media.veryLargeDesktop}) {
      font-size: ${options.sizes.veryLargeDesktop};
    }
    `
  }

  each(options, (value, key) => {
    if (key !== 'sizes') {
      fontCss += `
          ${key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)}: ${value};
        `
    }
  })

  return fontCss
}

/*
  ===MEDIA===
*/

export const media = Object.keys(theme.media).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${theme.media[label]}) {
      ${css(...args)}
    }
  `
  return acc
}, {})

/*
  ===ANIMATIONS===
*/

/**
 * Add custom transition
 * @param {String} prop prop affected by transition (default: all)
 * @param {Number} duration in seconds (default: 1)
 */
export function transition(props = ['all'], duration = theme.easing.duration, ease = theme.easing.default) {
  const transitions = props.map((prop) =>
    `${prop} ${duration}s ${ease}`
  )
  return `
    transition: ${transitions.join(', ')};
  `
}

/*
  ===GEOMETRY===
*/

/**
 * Display a +
 * @param {Number} size size of the + (default: 26)
 * @param {String} color theme.colors label (default: theme.colors.white)
 */
export function plus(size = 26, color = theme.colors.white) {
  return `
    display: block;
    position: relative;
    width: ${size}px;
    height: ${size}px;
    ${transition()}

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background: ${color};
    }

    &::before {
      width: ${size}px;
      height: 2px;
      top: ${(size / 2) - 1}px;
      left: 0;
    }

    &::after {
      width: 2px;
      height: ${size}px;
      top: 0;
      left: ${(size / 2) - 1}px;
    }

    &:hover {
      transform: rotate(180deg);
      ${transition()}
    }
  `
}

export const triangle = ({dir, size, unit, color = theme.colors.blue}) => {
  const base = `
    width: 0;
    height: 0;
  `  
  switch (dir) {
    case 'bottom':
      return `
      ${base}
      border-left: ${size * 0.8}${unit} solid transparent;
      border-right: ${size * 0.8}${unit} solid transparent;
      border-top: ${size}${unit} solid ${color};
    `
    case 'top':
      return `
      ${base}
      border-left: ${size}${unit} solid transparent;
      border-right: ${size}${unit} solid transparent;
      border-bottom: ${size}${unit} solid ${color};
    `
    case 'left':
      return `
      ${base}
      border-top: ${size * 1.15}${unit} solid transparent;
      border-bottom: ${size * 1.15}${unit} solid transparent;
      border-right: ${size}${unit} solid ${color};
    `
    default:
    case 'right':
      return `
      ${base}
      border-top: ${size * 1.15}${unit} solid transparent;
      border-bottom: ${size * 1.15}${unit} solid transparent;
      border-left: ${size}${unit} solid ${color};
    `
  }
}

export const TriangleStyle = styled.div`
  ${({ dir, size, unit }) =>  triangle({ dir, size, unit })}
`