import styled from 'styled-components'
export const TriangleStyle = styled.div`
  position: absolute;
  
  &.top {
    top: 0;
    left: 0;
    width: 100%;
    transform: scaleY(-1) translateY(84.5%);

    svg {
      width: 100%;
      height: auto;
    }
  }

  &.bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(84.5%);
    
    svg {
      width: 100%;
      height: auto;
    }
  }


  &.right {
    right: 0;
    top: 0;
    height: 100%;
    transform: scaleX(-1) translateX(-70%);

    svg {
      height: 100%;
      width: auto;
    }
  }

  &.left {
    left: 0;
    top: 0;
    height: 100%;
    transform: translateX(-70%);

    svg {
      height: 100%;
      width: auto;
    }
  }
}
`
