import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { throttle } from 'lodash'

import { setViewport, setScrollDirection } from '~state/app'
import GlobalStyle from '~styles/global'

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const resizeAction = throttle(() => dispatchViewport(), 250)
  const scrollAction = throttle(() => dispatchScrollDirection(), 250)
  let scrollPos = 0

  useEffect(() => {
    dispatchViewport()
    window.addEventListener('resize', resizeAction)
    window.addEventListener('scroll', scrollAction)

    return () => {
      window.removeEventListener('resize', dispatchViewport)
      window.removeEventListener('scroll', scrollAction)
    }
  }, [])

  const dispatchViewport = () => {
    dispatch(setViewport({
      width: window.innerWidth,
      height: window.innerHeight
    }))
  }

  const dispatchScrollDirection = () => {
    const { y } = document.body.getBoundingClientRect()
    if (y < scrollPos) {
      dispatch(setScrollDirection('down'))
    } else {
      dispatch(setScrollDirection('up'))
    }
    scrollPos = y
  }

  return (
    <>
      <GlobalStyle />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
