import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TweenMax, Power1, TimelineLite, Power2 } from 'gsap'

import { setHeaderAppearing, trianglePosSelector } from '~state/app'
import Logo from '~components/Logo'
import Triangle from '~components/Triangle'
import { HeaderStyle  } from './style'
import HeaderImage from './image'

const Header = ({ isThanks, children }) => {
  const dispatch = useDispatch()

  const ref = useRef(null)
  const textRef = useRef(null)
  const imageContainerRef = useRef(null)
  const triangleLeftRef = useRef(null)
  const triangleRightRef = useRef(null)
  const triangleTopRef = useRef(null)
  const triangleBottomRef = useRef(null)
  const logoRef = useRef(null)

  const showHeader = useSelector(state => state.app.showHeader)
  const headerAppearing = useSelector(state => state.app.headerAppearing)
  const trianglePositions = useSelector(trianglePosSelector)

  useEffect(() => {
    if (trianglePositions && headerAppearing) {
      dispatch(setHeaderAppearing(false))
      onAppear()
    }
  }, [trianglePositions])

  useEffect(() => {
    if (showHeader) {
      if (!headerAppearing) {
        onEnter()
      }
    } else if (!isThanks) {
      onLeave()
    }
  }, [showHeader])

  const onAppear = () => {
    new TimelineLite()
      .set([
        imageContainerRef.current,
        triangleLeftRef.current, triangleRightRef.current, 
        logoRef.current], { opacity: 0 })
      .set(triangleLeftRef.current, { x: trianglePositions.left.start })
      .set(triangleRightRef.current, { x: trianglePositions.right.start })
      .set(triangleTopRef.current, { y: trianglePositions.top.start })
      .set(triangleBottomRef.current, { y: trianglePositions.bottom.start })
      .set(textRef.current.children, { opacity: 0, y: 10 })
      .set(ref.current, { opacity: 1 })

      .to(imageContainerRef.current, 0.5, { opacity: 1 })
      .to([triangleLeftRef.current, triangleRightRef.current], 0.5, { opacity: 1 })
      .to(triangleLeftRef.current, 1, { x: trianglePositions.left.end, ease: Power2.easeOut }, 'triangle1-=0.25')
      .to(triangleRightRef.current, 1, { x: trianglePositions.right.end, ease: Power2.easeOut }, 'triangle1-=0.25')
      .to(triangleTopRef.current, 0.5, { y: trianglePositions.top.end, ease: Power1.easeOut }, 'triangle2-=0.5')
      .to(triangleBottomRef.current, 0.5, { y: trianglePositions.bottom.end, ease: Power1.easeOut }, 'triangle2-=0.5')
      .staggerTo(textRef.current.children, 0.5, { opacity: 1, y: 0, ease: Power1.easeOut }, 0.25)
      .to(logoRef.current, 0.2, { opacity: 1 })
  }

  const onEnter = () => {
    TweenMax.to(textRef.current, 0.5, { opacity: 1, y: 0, ease: Power1.easeOut })
    TweenMax.to(triangleBottomRef.current, 1, { y: trianglePositions.bottom.end, scale: 1, ease: Power1.easeOut })
    TweenMax.to(triangleTopRef.current, 1, { y: trianglePositions.top.end, ease: Power1.easeOut })
    TweenMax.to(triangleLeftRef.current, 1, { x: trianglePositions.left.end, ease: Power1.easeOut })
    TweenMax.to(triangleRightRef.current, 1, { x: trianglePositions.right.end, ease: Power1.easeOut })
    // TweenMax.to(window, 2, { scrollTo: { y: "#here", offsetY: -45 } })
  }
  
  const onLeave = () => {
    TweenMax.to(textRef.current, 0.25, { opacity: 0, y: -1000, ease: Power1.easeOut })
    TweenMax.to(triangleBottomRef.current, 1, { y: '-50%', scale: 30, ease: Power1.easeOut })
    TweenMax.to(triangleTopRef.current, 0.5, { y: '-100%', ease: Power1.easeOut })
    TweenMax.to(triangleLeftRef.current, 0.5, { x: '-100%', ease: Power1.easeOut })
    TweenMax.to(triangleRightRef.current, 0.5, { x: '100%', ease: Power1.easeOut })
    setTimeout(() => {
      TweenMax.to(window, 1, { scrollTo: 600 })
    }, 200) 
    // console.log(TweenMax.to(window, 2, { scrollTo: 250 }));
  }

  return (
    <HeaderStyle ref={ref} className={classNames({'-thanks': isThanks})}>
      <div className='triangle-container'>
        <Triangle className='triangle top' dir='top' ref={triangleTopRef} />
        <Triangle className='triangle right' dir='right' ref={triangleRightRef} />
        <Triangle className='triangle bottom' dir='bottom' ref={triangleBottomRef} />
        <Triangle className='triangle left' dir='left' ref={triangleLeftRef} />
      </div>
      <Logo ref={logoRef} />
      {!isThanks ? <div className='image-container' ref={imageContainerRef}>
        <HeaderImage />
      </div> : null}

      <div className='text-container' ref={textRef}>
        {children}
      </div>
    </HeaderStyle>
  )
}

Header.propTypes = {
  isThanks: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}


export default Header
